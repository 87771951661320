<template>
  <div>
    <header>
      <v-container class="no-fluid">
        <div class="header-logo">
          <img src="../assets/img/logo_v1.png" alt />
        </div>
        <div class="header-nav">
          <router-link class="header-btn" v-scroll-to="'#home'" to="/">
            <span>Inicio</span>
          </router-link>
          <router-link class="header-btn" to="/" v-scroll-to="'#credit'">
            <span>Credito</span>
          </router-link>
          <router-link class="header-btn" to="/" v-scroll-to="'#formulario'">
            <span>Formularios</span>
          </router-link>
          <router-link class="header-btn" to="/" v-scroll-to="'#about'">
            <span>¿Porque ARPOL?</span>
          </router-link>
          <router-link class="header-btn" to="/pqr">
            PQR
          </router-link>
        </div>
      </v-container>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../assets/scss/base.scss";
.head-top {
  //width: 100%;
  background-color: $rich-black-fogra-29;
  .container.no-fluid {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 10px 2rem;
  }
  .head-msj {
    width: 50%;
    p {
      font-size: 1rem;
      color: #fff;
      margin: 0;
    }
  }
  .head-link {
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
    flex: 1 1 auto;
    a {
      padding: 0 5px;
      font-size: 0.9rem;
      color: #f1f2eb;
      &:hover {
        color: #eca72c;
      }
    }
  }
}
header {
  position: fixed;
  z-index: 3;
  width: 100%;
  background: #fff;
  box-shadow: $shadow-2;
  //border-bottom: 15px solid $rosewood;
  min-height: 70px;
  align-items: center;
  .container.no-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    //display: flex;
    //flex: 1 1 auto;
    flex: 1 1 auto;
    //padding: 10px 2rem;
    @media #{$tablet-up} {
      padding: 5px 2rem;
      flex-direction: row;
    }
  }
  .header-logo {
    img {
      width: 190px;
      height: 40px;
      object-fit: contain;
      max-width: 100%;
      @media #{$tablet-up} {
        height: 70px;
      }
    }
  }
  .header-nav {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: flex-end;
    @media #{$tablet-up} {
      padding-left: 2rem;
    }
    a.header-btn {
      //text-transform: uppercase;
      font-size: 0.87rem;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: center;
      padding: 5px 5px;
      margin-right: 1px;
      min-width: 65px;
      font-family: "Roboto", serif;
      //text-transform: unset;
      text-decoration: none;
      font-weight: 700;
      color: #121212;
      //transform: translate3d(0px, 0px, 0px);
      &.router-link-exact-active.router-link-active {
        font-weight: 700;
        //border-bottom: 4px solid $rosewood;
        //border-radius: 20px;
        //background-color: #fddee0;
        color: $rosewood;
        //color: #cf0d18;
        span {
          font-size: 0.89rem;
          color: #121212;
          @media #{$tablet-up} {
            font-size: 1.1rem;
          }
          &:hover {
            color: $rosewood;
          }
        }
      }
      @media #{$tablet-up} {
        font-size: 1.1rem;
        margin-right: 10px;
        padding: 5px 10px;
      }
      &:hover {
        font-weight: 700;
        color: $rosewood;
        border-radius: 10px;
        background-color: #ffeff0;
      }
    }
  }
}
</style>
