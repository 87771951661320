<template>
  <v-app>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
//import HelloWorld from './components/HelloWorld'

export default {
  name: "App",

  components: {
    Header,
    Footer
    //HelloWorld,
  },

  data: () => ({
    //
  })
};
</script>
