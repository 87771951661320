import numeral from "numeral";
import Vue from "vue";
//filtre valor pesos
Vue.filter("currency", params => {
  return "$" + numeral(params).format("0,0");
});

// truncate

Vue.filter("truncate", (value, length) => {
  if (!value) return "";
  value = value.toString();
  if (value.length > length) {
    return value.substring(0, length) + "...";
  } else {
    return value;
  }
});
