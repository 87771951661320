<template>
  <div class="footer">
    <a href="https://diegoachury.com/es/">
      <img src="../assets/img/asigning1.png" alt="" />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../assets/scss/base.scss";
.footer {
  padding: 0 30px;
  margin-left: auto;
  a {
    font-size: 0.9rem;
    text-decoration: none;
    color: #000;
    p {
      color: #000;
      font-weight: bold;
      margin: 0;
    }
    img {
      width: 80px;
      max-width: 100%;
      object-fit: contain;
      @media #{$tablet-up} {
        width: 100px;
      }
    }
  }
}
</style>
