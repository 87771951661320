<template>
  <section class="sect-contact bg">
    <v-container>
      <div class="sect-service-title">
        <p>¿Aún tienes dudas?</p>
      </div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-row class="hd-line">
            <v-col cols="12" sm="12" md="3">
              <div class="ctn-logo-footer">
                <img src="../assets/img/logo_v1.png" alt="" />
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <div class="item-line">
                <div class="box-line">
                  <img src alt />
                  <p class="tl-info">Puntos de atención</p>
                  <div class="box-copy">
                    <p class="city">Neiva, Huila</p>
                    <p class="address">CALLE 10 No. 5-48 Local 202</p>
                    <p>Lunes a viernes</p>
                    <p class="hour">8:00 am – 12:00</p>
                    <p class="hour">2:00 pm – 6:00 pm</p>
                    <p>Sábados</p>
                    <p class="hour">9:00 am – 12:30 pm</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <div class="item-line">
                <div class="box-line">
                  <img src alt />
                  <p class="tl-info">Llámanos a nuestras líneas</p>
                  <div class="box-copy">
                    <p class="city">Neiva, Huila</p>
                    <p class="address">CALLE 10 No. 5-48 Local 202</p>
                    <p class="hour"><b>Fijo:</b> (098) 8667551</p>
                    <p class="hour"><b>Celular:</b></p>
                    <p class="hour">(304) 1167590</p>
                    <p class="hour">(304) 1118087</p>
                    <p class="hour">(310) 2778253</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <div class="item-line">
                <div class="box-line">
                  <img src alt />
                  <p class="tl-info">Por nuestras redes sociales</p>
                  <div class="box-copy icon-line">
                    <a
                      class="btn-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=100063535158309&mibextid=ZbWKwL"
                    >
                      <v-icon>mdi-facebook</v-icon>
                    </a>

                    <a
                      class="btn-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/arpolsas?igshid=YmMyMTA2M2Y="
                    >
                      <v-icon>mdi-instagram</v-icon>
                    </a>
                    <a
                      class="btn-icon"
                      href="https://api.whatsapp.com/send?phone=573102778253&app_absent=0"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                    </a>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="hd-line"></div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SubFooter",
};
</script>

<style lang="scss">
@import "../assets/scss/base.scss";
.sect-contact {
  padding-top: 30px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  @media #{$tablet-up} {
    padding-top: 130px;

    margin-bottom: 10px;
  }
  &.bg {
    background: #fff;
  }
  .sect-service-title {
    p {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 10px;
      color: $text-color;
    }
  }
  .hd-line {
    // display: flex;
    // flex-direction: row;
    .ctn-logo-footer {
      margin: 0 auto;
      text-align: center;
      padding-top: 20px;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      @media #{$tablet-up} {
        padding-top: 80px;
      }
      img {
        width: 180px;
        max-width: 100%;
      }
      p {
        font-size: 0.9rem;
        padding-top: 10px;
        line-height: 1;
        margin-bottom: 2px;
        font-weight: bold;
      }
      a {
        display: block;
        font-size: 0.9rem;
        color: $rosewood;
        margin-top: 5px;
        &:hover {
          color: #c92121;
        }
      }
    }
    .item-line {
      // flex: 1 1 auto;
      // width: 33.333%;
      //flex-grow: 1;
      .box-line {
        padding: 20px 20px 0;
        //text-align: center;
        p {
          text-transform: uppercase;
          font-family: "Roboto Condensed", sans-serif;
          &.tl-info {
            // background: #fcf2e0;
            //box-shadow: $shadow-3;
            // padding: 25px 20px;
            // border-radius: 10px;
            color: $text-color;
            font-weight: bold;
          }
        }
      }
      .box-copy {
        //margin-bottom: 20px;
        &.icon-line {
          display: flex;
          flex-direction: row;
          justify-content: left;
        }
        .btn-icon {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ddd;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          padding: 20px;
          margin: 0 10px;
          transition: all 0.3s ease-in-out;
          .v-icon.v-icon {
            color: $text-color;
          }
          &:hover {
            box-shadow: $shadow-3;
          }
        }
        p {
          font-family: "Roboto", cursive;
          text-transform: none;
          color: $text-color;
          margin: 0;
          font-size: 1rem;

          &.city {
            font-weight: bold;
            //font-size: 18px;
          }
          &.hour {
            font-size: 1rem;
            margin-bottom: 5px;
            //line-height: 1.3;
          }
        }
      }
    }
  }
}
</style>
