import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async sendForm({}, form) {
      try {
        //console.log("axios", form);
        let res = await axios.post("sendmail/creditArpol", form, {});
        //let { data } = res.data;
        //await dispatch('listShops');
        //commit("someOtherMutation");
        // actualice la lista y mande a producto listado
        console.log(res.status);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async sendPqr({}, form) {
      try {
        //console.log("axios", form);
        /* for (let [key, value] of form) {
          console.log(`${key}: ${value}`);
        } */
        let res = await axios.post("sendmail/pqrArpol", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        //let { data } = res.data;
        //await dispatch('listShops');
        //commit("someOtherMutation");
        // actualice la lista y mande a producto listado
        console.log(res.status);
      } catch (error) {
        console.error(error);
      }
    },
  },
  modules: {},
});
