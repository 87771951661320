<template>
  <div class="home">
    <Hero id="home" />
    <section class="sect-service" id="about">
      <v-container class="service-two">
        <v-row>
          <v-col cols="12" md="6">
            <div class="ctn_img_two">
              <img src="../assets/img/pexels-and.jpg" alt="" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="copy-two">
              <p>Conseguir su préstamo así de fácil.</p>
              <div class="ctn_steed">
                <div class="steed">
                  <img src="../assets/img/recurso29.png" alt="" />
                  <p>Escoge el monto a prestar</p>
                </div>
                <div class="steed">
                  <img src="../assets/img/recurso28.png" alt="" />
                  <p>Valida tu identidad</p>
                </div>
                <div class="steed">
                  <img src="../assets/img/recurso27.png" alt="" />
                  <p>Aprobamos el crédito y firmas</p>
                </div>
                <div class="steed">
                  <img src="../assets/img/recurso26.png" alt="" />
                  <p>Retira en su sucursal más cercana</p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="sect-service">
      <v-container>
        <div class="sect-service-title">
          <p>Conoce más de nuestros productos</p>
        </div>
        <v-row>
          <v-col cols="12" md="4">
            <div class="box-service" id="credit">
              <div
                class="box-service-head"
                style="background: url('./pr-4.png') no-repeat;                    
                     background-position: center; background-size: cover"
              >
                <div class="cover"></div>
                <p class="title-head">Créditos</p>
                <p class="sub-head">Adelanto de Nomina(Emergente)</p>
              </div>
              <div class="box-service-body">
                <p>
                  Si eres Cliente de Arpol SAS, se te prestara hasta un máximo
                  del valor de una de las cuotas que designes en tu crédito
                  ordinario principal y hasta un plazo no mayor a 30 días.
                </p>
              </div>
              <div class="box-service-foot">
                <!-- <v-btn elevation="2">Ver mas</v-btn> -->
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="box-service">
              <div
                class="box-service-head"
                style="background: url('./seguro.jpg') no-repeat; background-position: center; background-size: cover"
              >
                <div class="cover"></div>
                <p class="title-head">Asesoría</p>
                <p class="sub-head">ARPOL – Asesoramiento financiero</p>
              </div>
              <div class="box-service-body">
                <p>
                  Ponte en contacto con nuestros asesores para la elaboración de
                  un plan para la recuperación de su vida crediticia.
                </p>
              </div>
              <div class="box-service-foot">
                <!-- <v-btn elevation="2">Ver mas</v-btn> -->
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="box-service">
              <div
                class="box-service-head"
                style="background: url('./b-image.jpg') no-repeat; background-position: center; background-size: cover"
              >
                <div class="cover"></div>
                <p class="title-head">Cretidos</p>
                <p class="sub-head">Credi-prima</p>
              </div>
              <div class="box-service-body">
                <p>
                  Si eres Cliente de Arpol, puedes realizar hasta un 70% del
                  valor de tu prima por adelantado, y definir los plazos de pago
                </p>
              </div>
              <div class="box-service-foot">
                <!-- <v-btn elevation="2">Ver mas</v-btn> -->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div class="box-service">
              <div
                class="box-service-head"
                style="background: url('./seguro.jpg') no-repeat; background-position: center; background-size: cover"
              >
                <div class="cover"></div>
                <p class="title-head">Seguros</p>
                <p class="sub-head">SOAT – Seguro para vehículos</p>
              </div>
              <div class="box-service-body">
                <p>
                  Si eres cliente de Arpol SAS, por medio de este servicio, se
                  te prestara el dinero para el pago de tu SOAT, ayudándote a
                  salir de este apuro y permitiéndote usar tu vehículo sin
                  preocupaciones para el pago en plazo máximo hasta 3 meses.
                </p>
              </div>
              <div class="box-service-foot">
                <!-- <v-btn elevation="2">Ver mas</v-btn> -->
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="box-service large-top">
              <div class="cover"></div>
              <div class="box-service-head">
                <p class="title-head">Créditos</p>
                <p class="sub-head">
                  Crédito Ordinario (Mi Primer Crédito Arpol)
                </p>
              </div>
              <div class="box-service-body">
                <p>
                  Si eres empleado o pensionado, ARPOL SAS te facilitara hasta
                  un máximo de $10.000.000 dependiendo de tu capacidad y hasta
                  un plazo no mayor a 36 meses. Según el monto solicitado se
                  requerirá Codeudor.
                </p>
              </div>
              <div class="box-service-foot">
                <!-- <v-btn elevation="2">Solicitar</v-btn> -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="sect-service" id="formulario">
      <v-container class="service-two">
        <div class="ctn-tarifa">
          <div class="box-tarifa reds">
            <div class="copy-import">
              <p class="titl">Formularios</p>
              <p class="desc">
                A través de estos formularios ARPOL SAS coloca a su disposición
                para que puedan ser descargados por nuestros clientes o futuros
                clientes. Si tienes otro tipo de inquietud te invitamos a
                comunicarte con nuestra línea de atención.
              </p>
            </div>
          </div>
          <div class="box-tarifa white">
            <div class="tarifa-copy">
              <p class="tlt"></p>
              <div class="ctn-box-vla">
                <div class="box-vla">
                  <p>
                    Autorización Uso de Datos.
                  </p>
                  <a href="/files/auto-form.pdf" download>
                    <img src="../assets/img/contract.svg" alt="" />
                  </a>
                  <a href="/files/auto-form.pdf" download>Descargar</a>
                </div>
                <div class="box-vla">
                  <p>
                    Formulario Único de Vinculación Persona Natural.
                  </p>
                  <a href="/files/pn-form.pdf" download>
                    <img src="../assets/img/contract.svg" alt="" />
                  </a>
                  <a href="/files/pn-form.pdf" download>Descargar</a>
                </div>
                <div class="box-vla">
                  <p>
                    Formulario Único de Vinculación Persona Jurídica.
                  </p>
                  <a href="/files/pj-form.pdf" download>
                    <img src="../assets/img/contract.svg" alt="" />
                  </a>
                  <a href="/files/pj-form.pdf" download>Descargar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </section>
    <section class="sect-service" id="about">
      <v-container class="service-two">
        <div class="ctn-tarifa">
          <div class="box-tarifa white">
            <div class="tarifa-copy">
              <p class="tlt">Valor consulta DataCrédito</p>
              <div class="ctn-box-vla">
                <div class="box-vla">
                  <p>
                    Clientes:
                  </p>
                  <p class="desc">Quienes tramiten solicitud de crédito</p>
                  <p class="value">$24.000</p>
                </div>
                <div class="box-vla">
                  <p>
                    No clientes
                  </p>
                  <p class="desc">
                    Cualquier persona que quiera realizar una consulta.
                  </p>
                  <p class="value">$30.000</p>
                </div>
              </div>
              <div class="ctn-img">
                <img class="center" src="../assets/img/home_sup.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="box-tarifa morado">
            <div class="copy-import">
              <p class="titl">Importante</p>
              <p class="desc">
                ARPOL SAS es una compañía legalmente constituida en Colombia.
                Tiene por objeto social el desarrollo de productos y servicios
                que promuevan la inclusión financiera y el acceso al crédito de
                las personas naturales y de las micro empresas; así como
                actividades de compra de cartera. Asimismo;
              </p>
              <p class="desc">
                ARPOL SAS no desarrolla actividades de intermediación, captación
                de dineros del público u otras actividades similares que sean
                privativas de las instituciones financieras. Del mismo modo; la
                sociedad tampoco tiene dentro de su objeto social la
                administración, gestión o inversión de recursos de terceros en
                títulos inscritos en la Bolsa de Valores de Colombia.
              </p>
            </div>
          </div>
        </div>
      </v-container>
    </section>
    <section class="sect-service">
      <v-container>
        <div class="sect-service-title">
          <p>Nuestros Aliados</p>
        </div>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <div class="aliados">
              <a href="https://www.sic.gov.co/" target="_blank">
                <img src="../assets/img/logo_indus.png" alt="" />
              </a>
              <a href="https://www.datacredito.com.co/">
                <img src="../assets/img/logo_midc.svg" alt="" />
              </a>
              <a
                href="https://consultaprocesos.ramajudicial.gov.co/Procesos/Bienvenida"
                target="_blank"
              >
                <img
                  class="small-log"
                  src="../assets/img/rama_judicial.png"
                  alt=""
                />
              </a>
              <a
                href="https://www.adres.gov.co/eps/regimen-contributivo/Paginas/afiliados-compensados.aspx"
                target="_blank"
              >
                <img
                  class="small-adres"
                  src="../assets/img/logo_adres.jpg"
                  alt=""
                />
              </a>
              <a
                href="https://www.supersociedades.gov.co/SitePages/Inicio.aspx"
                target="_blank"
              >
                <img
                  class="small-super"
                  src="../assets/img/logo_super.svg"
                  alt=""
                />
              </a>
              <a href="https://www.rues.org.co/" target="_blank">
                <img class="small-super" src="../assets/img/rues.png" alt="" />
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="info-extra">
      <div class="info-bg">
        <div class="info-cover">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#60060b"
              fill-opacity="1"
              d="M0,64L120,74.7C240,85,480,107,720,96C960,85,1200,43,1320,21.3L1440,0L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
            ></path>
          </svg>
        </div>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div class="ctn-info-copy">
                <div>
                  <p class="line"></p>
                  <p class="info-name">¡Evita ser estafado!</p>
                  <p>
                    La responsabilidad y compromiso es con nuestros clientes.
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="icon-ti">
                <div class="ctn-ti">
                  <img src="../assets/img/1xico.png" alt="" />
                  <p>Sin Intermediarios</p>
                </div>
                <div class="ctn-ti">
                  <img src="../assets/img/2xico.png" alt="" />
                  <p>Sin Anticipos</p>
                </div>
                <div class="ctn-ti">
                  <img src="../assets/img/3xico.png" alt="" />
                  <p>Sin información bancaria</p>
                </div>
              </div>

              <!-- <div class="ctn-info-img">
                <img src="../assets/img/hack_grey.jpg" alt />
              </div> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>

    <SubFooter />
  </div>
</template>

<script>
// @ is an alias to /src

import Hero from "@/components/Hero.vue";
import SubFooter from "@/components/SubFooter.vue";

export default {
  name: "Home",
  components: {
    Hero,
    SubFooter
  },
  methods: {}
};
</script>

<style lang="scss">
@import "../assets/scss/base.scss";
.sect-contact {
  padding-top: 30px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  @media #{$tablet-up} {
    padding-top: 130px;

    margin-bottom: 10px;
  }
  &.bg {
    background: #fff;
  }
  .sect-service-title {
    p {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 10px;
      color: $text-color;
    }
  }
  .hd-line {
    // display: flex;
    // flex-direction: row;
    .ctn-logo-footer {
      margin: 0 auto;
      text-align: center;
      padding-top: 20px;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      @media #{$tablet-up} {
        padding-top: 80px;
      }
      img {
        width: 180px;
        max-width: 100%;
      }
    }
    .item-line {
      // flex: 1 1 auto;
      // width: 33.333%;
      //flex-grow: 1;
      .box-line {
        padding: 20px 20px 0;
        //text-align: center;
        p {
          text-transform: uppercase;
          font-family: "Roboto Condensed", sans-serif;
          &.tl-info {
            // background: #fcf2e0;
            //box-shadow: $shadow-3;
            // padding: 25px 20px;
            // border-radius: 10px;
            color: $text-color;
            font-weight: bold;
          }
        }
      }
      .box-copy {
        //margin-bottom: 20px;
        &.icon-line {
          display: flex;
          flex-direction: row;
          justify-content: left;
        }
        .btn-icon {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ddd;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          padding: 20px;
          margin: 0 10px;
          transition: all 0.3s ease-in-out;
          .v-icon.v-icon {
            color: $text-color;
          }
          &:hover {
            box-shadow: $shadow-3;
          }
        }
        p {
          font-family: "Roboto", cursive;
          text-transform: none;
          color: $text-color;
          margin: 0;
          font-size: 1rem;

          &.city {
            font-weight: bold;
            //font-size: 18px;
          }
          &.hour {
            font-size: 1rem;
            margin-bottom: 5px;
            //line-height: 1.3;
          }
        }
      }
    }
  }
}

.sect-service {
  position: relative;
  z-index: 1;
  padding-top: 10px;
  margin-bottom: 10px;
  @media #{$tablet-up} {
    margin-bottom: 50px;
  }
  &.bg {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(1, 22, 39, 1) 100%
    );
  }
  .ctn-tarifa {
    display: flex;
    flex-direction: column;

    @media #{$tablet-up} {
      flex-direction: row;
    }
    .box-tarifa {
      //box-shadow: $shadow-1;
      @media #{$tablet-up} {
        width: 50%;
      }
      &.white {
        background-color: #fff;
      }
      &.reds {
        background-color: $rosewood;
      }
      &.morado {
        background-color: #150b40;
      }
      .copy-import {
        padding: 20px;
        p {
          color: #fff;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 2.1rem;
          &.desc {
            font-family: "Roboto", sans-serif;
            font-size: 1rem;
            line-height: 1.6;
          }
        }
      }
      .tarifa-copy {
        @media #{$tablet-up} {
          padding: 10px 20px;
        }
        .ctn-box-vla {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          margin-bottom: 20px;
          flex-wrap: wrap;
          @media #{$tablet-up} {
            flex-wrap: nowrap;
          }
          .box-vla {
            display: flex;
            box-shadow: $shadow-2;
            width: 47%;
            padding: 10px;
            border-radius: 10px;
            margin: 5px 5px;
            flex-direction: column;
            @media #{$tablet-up} {
              margin: 0 10px;

              width: 50%;
              flex: 1 1 auto;
            }
            a {
              margin: 5px 0;
              text-align: center;
              font-weight: bold;
              text-decoration: none;
              &:hover {
                color: $rosewood;
              }
            }
            img {
              width: 64px;
              object-fit: contain;
            }
            &:hover {
              box-shadow: $shadow-3;
            }
          }
        }
        p.tlt {
          font-family: "Roboto Condensed", sans-serif;
          font-size: 2.1rem;
        }
        p {
          font-family: "Roboto Condensed", sans-serif;
          //font-size: 2.1rem;
          margin-bottom: 0;
          &.desc {
            font-family: "Roboto", sans-serif;
            line-height: 1.2;
          }
          &.value {
            font-size: 1.4rem;
          }
        }
        .ctn-img {
          margin: 0 auto;
          text-align: center;
          img.center {
            width: 150px;
            max-width: 100%;
          }
        }
      }
    }
  }
  .sect-service-title {
    p {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 40px;
      color: $rich-black;
    }
  }
  .hd-line {
    display: flex;
    flex-direction: row;
    .item-line {
      flex: 1 1 auto;
      .box-line {
        text-align: center;
      }
    }
  }
  .box-service {
    box-shadow: $shadow-3;
    margin: 10px 10px 16px;
    min-height: 210px;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: $shadow-4;
    }
    //height: 100%;
    //margin-bottom: 16px;
    &.large-top {
      margin: 10px 0;
      height: calc(100% - 26px);
      background: url("../assets/img/person-card.jpg") no-repeat;
      background-size: cover;
      background-position: center;
      .box-service-head {
        min-height: 200px;
        padding: 10px 20px;
        // background: url('../assets/img/person-card.jpg') no-repeat;
        // background-size: cover;
        // background-position: center;
      }
      .box-service-body {
        position: relative;
        z-index: 1;
        p {
          color: #fff;
        }
      }
    }
    .cover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
    }
    .box-service-head {
      min-height: 200px;
      display: flex;
      justify-content: center;
      text-align: left;
      flex-direction: column;
      position: relative;
      padding: 10px 20px;
      margin-bottom: 10px;

      p {
        position: relative;
        z-index: 1;
        &.title-head {
          color: $marigold;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0;
          line-height: 1.4;
          text-transform: uppercase;
        }
        &.sub-head {
          color: #fff;
          line-height: 1.3;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
    .box-service-body {
      padding: 20px;
      min-height: 250px;
      p {
        color: $text-color-grey;
        font-size: 1.1rem;
        line-height: 1.5;
        margin-bottom: 50px;
      }
    }
    .box-service-foot {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      padding: 20px 20px;
    }
  }
  .service-two {
    padding-top: 50px;
    //background: #f50;
    .ctn_img_two {
      img {
        max-width: 100%;
      }
    }
    .copy-two {
      p {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 2.1rem;
        line-height: 1.2;
        //width: ;
        @media #{$tablet-up} {
          width: 60%;
        }
      }
    }
    .ctn_steed {
      padding-top: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media #{$tablet-up} {
        flex-wrap: nowrap;
      }
      .steed {
        padding: 0 10px;
        flex: 1 1 auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        text-align: center;
        width: 50%;
        @media #{$tablet-up} {
          width: 33%;
        }
        img {
          width: 64px;
          height: 100px;
          object-fit: contain;
          max-width: 100%;
        }
        p {
          margin-top: 15px;
          width: 100%;
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }
  .aliados {
    margin: 0 auto;
    text-align: center;
    img {
      padding: 0 20px;
      max-width: 100%;
      width: 190px;
      object-fit: contain;
      &.small-super {
        width: 270px;
      }
      &.small-adres {
        width: 170px;
      }
      &.small-log {
        width: 120px;
      }
      @media #{$tablet-up} {
        width: 230px;
      }
    }
  }
}
.info-extra {
  margin-bottom: 50px;
  .info-bg {
    position: relative;
    padding: 60px 0 10px;
    background: $rosewood;
    .info-cover {
      position: absolute;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 0px;
    }
    .ctn-info-copy {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      p.line {
        border: 4px solid #fff;
        width: 50px;
        height: 2px;
      }
      p {
        font-size: 1.3rem;
        font-family: "Roboto", cursive;
        color: #fff;
        line-height: 1.2;
        &.info-name {
          font-family: "Roboto Condensed", sans-serif;
          color: $marigold;
          font-size: 3rem;
          line-height: 1.2;
          margin-bottom: 10px;
        }
      }
    }
    .icon-ti {
      display: flex;
      flex-direction: row;
      .ctn-ti {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          padding-top: 10px;
          color: #fff;
          font-family: "Roboto Condensed", sans-serif;
        }
        img {
          max-width: 100%;
          width: 70px;
        }
      }
    }

    .ctn-info-img {
      border-radius: 10px;
      width: 100%;
      img {
        box-shadow: $shadow-3;
        border-radius: 10px;
        height: 280px;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
