import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import filtros
import axios from "axios";
import "./plugins/filters";
import vuetify from "./plugins/vuetify";
import VueAgile from "vue-agile";
import VueScrollTo from "vue-scrollto";

Vue.config.productionTip = false;

Vue.use(VueAgile);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;
//axios.defaults.baseURL = 'https://apiway.lubumas.com/api/';
axios.defaults.baseURL = "https://apiemail.lubumas.com/api/";
//axios.defaults.baseURL = "http://localhost:5003/api/";

new Vue({
  router,
  store,
  vuetify,
  // eslint-disable-next-line prettier/prettier
  render: (h) => h(App),
}).$mount("#app");
