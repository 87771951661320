<!-- eslint-disable prettier/prettier -->
<template>
  <div class="hero">
    <div class="hero-bg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,32L80,42.7C160,53,320,75,480,122.7C640,171,800,245,960,261.3C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        />
      </svg>
    </div>
    <v-container class="container-custom">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="7">
          <div class="cont-carousel md-hide">
            <agile
              :autoplay="true"
              :autoplaySpeed="7000"
              :navButtons="false"
              :fade="true"
            >
              <div class="card-carousel">
                <div class="card-carousel-img">
                  <img src="../assets/img/pro-1.jpg" alt />
                </div>
                <div class="card-carousel-copy">
                  <p class="name">Antonio Rodriguez - Bogota, Co</p>
                  <p>
                    Gracias a
                    <b>Arpol</b> pude conseguir el crédito para mi motocicleta.
                  </p>
                </div>
              </div>
              <div class="card-carousel">
                <div class="card-carousel-img">
                  <img src="../assets/img/pro-2.jpg" alt />
                </div>
                <div class="card-carousel-copy">
                  <p class="name">Ana Maria Neiva, Co</p>
                  <p>
                    Gracias a
                    <b>Arpol</b> pude matricularme este semestre.
                  </p>
                </div>
              </div>
              <div class="card-carousel">
                <div class="card-carousel-img">
                  <img src="../assets/img/pro-3.jpg" alt />
                </div>
                <div class="card-carousel-copy">
                  <p class="name">Camilo Santos Bogota, Co</p>
                  <p><b>Arpol</b> un crédito rápido y fácil, Gracias!</p>
                </div>
              </div>
            </agile>
          </div>
          <div class="hero-copy">
            <div class="copy">
              <h1>Calcula y Pide tu crédito!</h1>
              <p class="sub">
                Estudio y respuesta rápida con desembolso entre
                <b>24-48 Horas</b>
              </p>
              <v-form
                ref="form"
                v-model="valid"
                method="post"
                enctype="multipart/form-data"
                lazy-validation
              >
                <p>¿Cuánto dinero necesitas?</p>
                <p class="num-valor">
                  <span>{{ bpm | currency }}</span>
                </p>
                <v-slider
                  dense
                  color="#60060b"
                  v-model="bpm"
                  hint="Im a hint"
                  max="10000000"
                  min="100000"
                  ref="mount"
                  :rules="[(v) => !!v || 'es requerida']"
                  required
                ></v-slider>
                <p>¿En cuánto tiempo puedes pagarlo?</p>
                <p class="num-valor">
                  <span>
                    {{ date }}
                    <span class="mini-span">{{ nameDate }}</span>
                  </span>
                </p>
                <v-slider
                  color="#60060b"
                  v-model="date"
                  hint="Im a hint"
                  max="36"
                  min="1"
                  ref="time"
                  :rules="[(v) => !!v || 'es requerida']"
                  required
                ></v-slider>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <p class="title-label">Sede más cercana</p>
                    <v-select
                      outlined
                      color="#60060b"
                      :items="itemCity"
                      v-model="sede"
                      ref="sede"
                      :rules="[(v) => !!v || 'es requerida']"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <p class="title-label">¿Qué producto te interesa?</p>
                    <v-select
                      outlined
                      color="#60060b"
                      :items="itemProduct"
                      v-model="product"
                      ref="product"
                      :rules="[(v) => !!v || 'es requerida']"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      outlined
                      color="#60060b"
                      label="NOMBRE COMPLETO"
                      ref="name"
                      v-model="name"
                      :rules="[(v) => !!v || 'es requerida']"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      outlined
                      color="#60060b"
                      label="SU NÚMERO DE CELULAR"
                      placeholder="3xxx-xxxxxxxx"
                      v-model="movil"
                      :rules="[(v) => !!v || 'es requerida']"
                      ref="movil"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-btn
                  :disabled="!valid"
                  @click="validate"
                  elevation="2"
                  color="#eca72c"
                  large
                  >Solicitar credito</v-btn
                >
              </v-form>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <div class="cont-carousel sm-hide">
            <agile
              :autoplay="true"
              :autoplaySpeed="7000"
              :navButtons="false"
              :fade="true"
            >
              <div class="card-carousel">
                <div class="card-carousel-img">
                  <img src="../assets/img/pro-1.jpg" alt />
                </div>
                <div class="card-carousel-copy">
                  <p class="name">Antonio Rodriguez - Bogota, Co</p>
                  <p>
                    Gracias a
                    <b>Arpol</b> pude conseguir el crédito para mi motocicleta.
                  </p>
                </div>
              </div>
              <div class="card-carousel">
                <div class="card-carousel-img">
                  <img src="../assets/img/pro-2.jpg" alt />
                </div>
                <div class="card-carousel-copy">
                  <p class="name">Ana Maria Neiva, Co</p>
                  <p>
                    Gracias a
                    <b>Arpol</b> pude matricularme este semestre.
                  </p>
                </div>
              </div>
              <div class="card-carousel">
                <div class="card-carousel-img">
                  <img src="../assets/img/pro-3.jpg" alt />
                </div>
                <div class="card-carousel-copy">
                  <p class="name">Camilo Santos Bogota, Co</p>
                  <p><b>Arpol</b> un crédito rápido y fácil, Gracias!</p>
                </div>
              </div>
            </agile>
          </div>
          <div class="ban-text">
            <div>
              <div class="ban-title">
                <p>¿NO TIENES VIDA CREDITICIA?</p>
                <p>¿ESTAS REPORTADO?</p>
                <p class="desc">
                  Solicitar su crédito es muy fácil solo se necesita:
                </p>
              </div>

              <div class="info-hero">
                <div class="info-img">
                  <img src="../assets/img/ident-white.svg" alt />
                </div>
                <div class="info-copy">
                  <p>Fotocopia de tu cedula</p>
                </div>
              </div>
              <div class="info-hero">
                <div class="info-img">
                  <img src="../assets/img/cert-white.svg" alt />
                </div>
                <div class="info-copy">
                  <p>Desprendibles de nómina de los últimos 2 meses</p>
                </div>
              </div>
              <div class="info-hero">
                <div class="info-img">
                  <img src="../assets/img/des-white.svg" alt />
                </div>
                <div class="info-copy">
                  <p>Certificado laboral</p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar color="#000" v-model="snackbar" :timeout="timeout">
      <span style="color: #fff">{{ text }} </span>
      <template v-slot:action="{ attrs }">
        <v-btn color="#fff" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import { mapActions } from "vuex";
export default {
  name: "Hero",
  props: {
    msg: String,
  },
  components: {
    agile: VueAgile,
  },
  data: () => ({
    snackbar: false,
    text: "Gracias en minutos uno de nuestros asesores le contactara.",
    timeout: -1,
    valid: true,
    bpm: 10000,
    date: 1,
    sede: null,
    product: null,
    name: null,
    movil: null,
    email: null,
    itemCity: ["Neiva, Huila"],
    itemProduct: [
      "Crédito Ordinario",
      "Adelanto de Nomina(Emergente)",
      "SOAT–Seguro para vehículos",
      "Credi-prima",
    ],
    interval: null,
    isPlaying: false,
  }),
  methods: {
    ...mapActions(["sendForm"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.submitInfo();
        this.snackbar = true;
      }
    },
    submitInfo() {
      try {
        let formData = {};
        // formData.append("time", this.date);

        this.email = "gerente@arpol.com.co";
        formData.mount = this.bpm;
        formData.time = this.date;
        formData.subject = `Interesado en - ${this.product}`;
        formData.email = this.email;
        formData.sede = this.sede;
        formData.product = this.product;
        formData.name = this.name;
        formData.movil = this.movil;
        // formData.append("email", this.email);
        // formData.append("sede", this.sede);
        // formData.append("product", this.product);
        // formData.append("name", this.name);
        // formData.append("movil", this.movil);
        console.log("formdata", formData);
        // send data
        this.sendForm(formData);
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      }
    },
    decrement() {
      this.bpm--;
    },
    increment() {
      this.bpm++;
    },
  },
  computed: {
    nameDate() {
      if (this.date === 1) {
        return "Mes";
      }
      return "Meses";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/base.scss";
.no-padding {
  padding: 0;
}
.sm-hide {
  display: none !important;
  @media #{$tablet-up} {
    display: block !important;
  }
}
.md-hide {
  @media #{$tablet-up} {
    display: none !important;
  }
}
.hero {
  position: relative;
  margin-top: 60px;
  margin-bottom: 0px;
  padding-bottom: 90px;
  background: rgb(96, 6, 11);
  background: -moz-linear-gradient(90deg, #60060b 0%, #ba0c15 100%);
  background: -webkit-linear-gradient(90deg, #60060b 0%, #ba0c15 100%);
  background: linear-gradient(90deg, #60060b 0%, #ba0c15 100%);
  background-size: 450% 250%;
  animation: gradient 15s ease infinite;
  @media #{$tablet-up} {
    margin-bottom: 60px;
  }
  svg {
    display: block;
  }
  .hero-bg {
    position: absolute;
    width: 100%;
    bottom: 0px;
    //padding-bottom: 100px;
    img {
      width: 100%;
      object-fit: cover;
      max-width: 100%;
    }
    @media #{$tablet-up} {
      bottom: -48px;
    }
  }
  .ban-text {
    display: flex;
    //height: 80%;
    padding-top: 20px;
    //flex-direction: column;
    align-items: center;
    .ban-title {
      margin-bottom: 30px;
      p {
        font-family: "Roboto Condensed", sans-serif;
        //font-family: "Bebas Neue", cursive;
        font-size: 2.5rem;
        text-align: center;
        //font-family: "Roboto Condensed", sans-serif;
        line-height: 1.3;
        //font-weight: bold;
        color: #fff;
        &.desc {
          font-size: 1.5rem;
        }
      }
    }
    .info-hero {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      width: 80%;
      margin-bottom: 30px;
      //justify-content: center;
      //margin-bottom: 10px;
      .info-img {
        display: flex;
        //justify-content: center;
        align-items: center;
        //width: 48px;
        padding: 16px;
        //height: 48px;
        //border: 4px solid $marigold;
        border-radius: 50%;
        background: #611a0b;
        box-shadow: $shadow-3;
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        margin-right: 20px;
      }
      .info-copy {
        p {
          font-family: "Roboto", sans-serif;
          color: #fff;
          text-align: left;
          font-size: 1.3rem;
          margin-bottom: 0;
        }
      }
    }
  }
  .hero-copy {
    margin: 10px auto 30px;
    //margin-top: 70px;
    //padding-top: 90px;
    position: relative;
    display: flex;
    width: 100%;

    //margin-left: 30px;
    z-index: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: $shadow-3;
    @media #{$tablet-up} {
      margin: 50px auto 30px;
      width: 90%;
    }
    .copy {
      width: 50%;
      padding: 40px 30px;
      flex: 1 1 0px;
      text-align: center;
      .v-btn {
        height: 65px;
        width: 60%;
        font-weight: bold;
      }
      h1 {
        font-family: "Roboto Condensed", cursive;
        color: $text-color;
        font-size: 2.1rem;
        line-height: 1.1;
        margin-bottom: 10px;
      }
      p {
        font-family: "Roboto", sans-serif;
        color: $text-color-grey;
        font-size: 1.09rem;
        margin-bottom: 0;
        &.title-label {
          font-size: 1rem;
          text-align: left;
          margin-bottom: 5px;
        }

        &.sub {
          font-size: 1rem;
          margin-bottom: 20px;
        }
        b {
          color: $text-color;
        }
      }
      .v-input input {
        font-size: 1rem;
        //min-height: 80px;
      }
      p.num-valor {
        font-family: "Roboto Condensed", cursive;
        color: $text-color;
        font-size: 2rem;
        margin-bottom: 0;
        span {
          &.mini-span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .hero-img {
    flex: 1 1 0px;
  }
  .hero-cal {
    flex: 1 1 0px;
  }
}

.cont-carousel {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 30px;
  //overflow: hidden;
  //margin: 0 auto;
  .agile__list {
    padding: 0 2px;
    //overflow: inherit !important;
  }
  .agile__track {
    //box-shadow: $shadow-4;
  }
  .card-carousel {
    margin: 20px 1px 20px 1px;
    background: $marigold;
    display: flex;
    flex-direction: row;
    //border: 1px solid #ddd;
    box-shadow: $shadow-3;
    border-radius: 10px;
    width: 75%;
    .card-carousel-img {
      padding: 10px;
      flex: 1 1 auto;
      img {
        box-shadow: $shadow-3;
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .card-carousel-copy {
      flex: 1 1 auto;
      padding: 10px;
      p {
        font-size: 1.2rem;
        margin-bottom: 0;
        line-height: 1.2;
        &.name {
          font-size: 1.1rem;
          font-family: "Roboto Condensed", sans-serif;
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 47%;
  }
  50% {
    background-position: 100% 54%;
  }
  100% {
    background-position: 0% 47%;
  }
}
</style>
